import React, { Component } from 'react'
import URLSearchParams from 'url-search-params'
import { connect } from 'react-redux'
import { persistStore } from 'redux-persist'

import RegistrationBreadcrumb from '../Authentication/Registration/components/RegistrationBreadcrumb'
import PaymentInfoPanel from './PaymentInfoPanel'
import { alignStepperToBox, resizeWindow } from '../Authentication/Registration/helpers/resizeWindow'

import { CURRENT_URL_COOKIE, PURCHASE_URL_COOKIE } from '../constants/application'
import { datalayerPushGTM, datalayerPushTransaction, datalayerPush, getCookieValue, getURLFullPath } from '../helpers/application'
import { emitGa4PageView } from '../../../libs/google_analytics_helpers'

class PaymentInfoPage extends Component {
  state = {
    purchaseError: null
  }

  componentDidMount () {
    resizeWindow()
    alignStepperToBox()
    datalayerPush({ event: 'page_name', page_name: 'payment-informations' })
    datalayerPushGTM('hitVirtualPageView')
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('origin') === 'booking') {
      emitGa4PageView('cc_registration')
    }
  }

  componentDidUpdate () {
    resizeWindow()
    alignStepperToBox()
  }

  handleCancel = () => {
    const redirectUrl = getCookieValue(PURCHASE_URL_COOKIE) || getCookieValue(CURRENT_URL_COOKIE, '/')
    window.location = getURLFullPath(redirectUrl)
  }

  handleSubmitPurchase = async (professionalUse) => {
    const { history, offer, travelInfos, discountCode, localePrefix, partnerCard, type, user } = this.props

    const res = await fetch(`${localePrefix}/purchases/create_react`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify({
        offer_id: offer.offerBuilder.id,
        ...travelInfos,
        discount_code: discountCode ? discountCode.code : null,
        partner_loyalty_number: partnerCard ? partnerCard.cardNumber : null,
        partner: partnerCard ? partnerCard.id : null,
        multiple_entries: offer.offerBuilder.multipleEntries.allowSelected,
        personal_data_com: null,
        price: offer.offerBuilder.amount.totalWithoutOptions,
        professional: professionalUse
      })
    })
    const json = await res.json()

    if (json.purchase === undefined) {
      datalayerPushGTM('form-tracking', 'Users', 'purchase/error', json.error)
      history.push({
        pathname: `${localePrefix}/purchases/confirmation`,
        search: `?utm_nooverride=true&success=false${type === 'subscription' ? '&type=subscription' : ''}`,
        state: { error: json.error }
      })
    } else if (json.error) {
      datalayerPushGTM('form-tracking', 'Users', 'purchase/error', json.error)
      this.setState({ purchaseError: json.error })
    } else {
      datalayerPushGTM('form-tracking', 'Users', 'purchase/success', json.purchase.id)
      if (window.Cookies.get('new_purchase_confirmation') !== undefined) {
        datalayerPushTransaction(json.purchase)
        datalayerPushGTM('serverEventTransaction')
        window.ga('send', 'event', 'purchase', 'transaction/success', user.id)
      }
      persistStore(this.props).purge()
      history.push({
        pathname: `${localePrefix}/purchases/${json.purchase.id}/confirmation`,
        search: `?utm_nooverride=true&success=true${type === 'subscription' ? '&type=subscription' : ''}`,
        state: { purchase: json.purchase, purchaseState: json.purchase_state }
      })
    }
  }

  render () {
    const { localePrefix, offer, location } = this.props
    const { purchaseError } = this.state
    const urlParams = new URLSearchParams(location.split('?')[1])
    const callingPage = urlParams.get('origin') === 'header' ? 'registration' : 'booking'

    return (
      <div className='react-component registration-payment-info-step'>
        <div className='row'>
          <RegistrationBreadcrumb
            previousSteps={this.props.previousSteps} activeStepId='payment_details' futureSteps={this.props.futureSteps}
            location={location} localePrefix={localePrefix} offerId={offer ? offer.offerBuilder.id : null}
            stepperClasses='stepper--no-padding'
            insideLogoClasses='registration__logo--hidden-on-tablet'
            additionalClasses='registration__breadcrumb-wrapper--centered-on-tablet'
          />
        </div>

        <div className='row registration__block--right registration__block--no-height-limit col-md-12'>
          <PaymentInfoPanel
            {...this.props}
            callingPage={callingPage}
            onCancel={callingPage === 'booking' ? null : this.handleCancel}
            handleSubmitPurchase={this.handleSubmitPurchase}
          />

          {purchaseError &&
            <p><small className='text--orange' role='alert'>{purchaseError}</small></p>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  purchase: {
    discountCode,
    travelInfos,
    offer,
    partnerCard
  },
  resources: { mobilePurchaseTunnel, mobilePurchaseTunnelPaymentInside, localePrefix },
  railsContext: { location },
  search: { params: { type } },
  user
}) => ({
  mobilePurchaseTunnel,
  mobilePurchaseTunnelPaymentInside,
  discountCode,
  localePrefix,
  partnerCard,
  location,
  offer,
  travelInfos,
  type,
  user
})

export default connect(mapStateToProps)(PaymentInfoPage)
