import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import Collapsible from 'react-collapsible'
import moment from 'moment'
import { connect } from 'react-redux'
import classNames from 'classnames'

import PaymentInfoPanel from '../PaymentInfoPanel'
import SvgImage from '../../components/SvgImage'

import iconEdit from 'Assets/images/svg-on-react/icon-pencil.svg'
import { emitGa4PageView } from '../../../../libs/google_analytics_helpers'

class PaymentSection extends Component {
  handleSubmitPurchase = async () => {
    await this.props.handleSubmitPurchase()
  }

  componentDidMount () {
    if (this.props.showPaymentForm) emitGa4PageView('cc_registration')
  }

  componentDidUpdate () {
    if (this.props.showPaymentForm) emitGa4PageView('cc_registration')
  }

  render () {
    const { billingIdentity, showPaymentForm, mobilePurchaseTunnelPaymentInside, forwardedRef, warning } = this.props
    const blockClassName = classNames('purchase-summary__block row', {
      'purchase-summary__block--orange': warning
    })

    return (
      <div className={blockClassName} ref={forwardedRef}>
        { (mobilePurchaseTunnelPaymentInside || billingIdentity) &&
          <FormattedMessage id='purchase.purchase_summary.payment.title'>
            {(message) => (
              <Collapsible trigger={message} open={mobilePurchaseTunnelPaymentInside || billingIdentity !== null}>
                { !showPaymentForm && billingIdentity &&
                  <div className='purchase-summary__block--flex'>
                    <div className='purchase-summary__info-block'>
                      <p>{billingIdentity.cardNumber}</p>
                      <p>{billingIdentity.ownerName}</p>
                      <p>
                        <FormattedMessage id='purchase.purchase_summary.format_expires_at' >
                          {f =>
                            <FormattedMessage
                              id='purchase.purchase_summary.payment.expires_at'
                              values={{ date: moment(billingIdentity.expiresAt).format(f) }} />
                          }
                        </FormattedMessage>
                      </p>
                    </div>
                    <div onClick={this.props.handleShowPaymentForm} data-automation-id='purchase-summary__payment-edit'>
                      <SvgImage svg={iconEdit} className='svg-image--clickable' />
                    </div>
                  </div>
                }
                { showPaymentForm &&
                  <PaymentInfoPanel
                    {...this.props}
                    callingPage='booking'
                    hideTitle
                    handleSubmitPurchase={this.handleSubmitPurchase}
                    turnoffWarning={this.props.turnoffWarning}
                    handleStripeChange={this.props.handleStripeChange}
                    handleStripeError={this.props.handleStripeError}
                  />
                }
              </Collapsible>
            )}
          </FormattedMessage>
        }
      </div>
    )
  }
}

const mapStateToProps = ({
  purchase,
  resources: { localePrefix },
  railsContext: { location }
}) => ({ localePrefix, location, purchase })

export default connect(mapStateToProps)(PaymentSection)
