import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'

import { exitFullscreen, requestFullscreen } from 'Helpers/fullscreen'

export default class ModalOp extends Component {
  static propTypes = {
    id: PropTypes.string,
    desktopOnly: PropTypes.bool,
    mobileOnly: PropTypes.bool,
    autoHideOnResize: PropTypes.bool,
    fullScreen: PropTypes.bool,
    onModalClosed: PropTypes.func
  }

  componentDidMount () {
    this.$el.on('show.bs.modal', this.handleModalShow)
    this.$el.on('hidden.bs.modal', this.handleModalHidden)
    $(window).on('scroll resize', this.handleResize)
    $(window).on('orientationchange', this.handleOrientationChange)

    this.handleResize()
  }

  componentWillUnmount () {
    $(window).off('scroll resize', this.handleResize)
    $(window).off('orientationchange', this.handleOrientationChange)
  }

  handleResize = () => requestAnimationFrame(() => {
    const { autoHideOnResize, mobileOnly, desktopOnly } = this.props

    if ($(window).width() < 992) {
      this.$el.removeClass('fade')
      if (desktopOnly && autoHideOnResize) {
        this.$el.modal('hide')
      }
    } else if (mobileOnly && autoHideOnResize) {
      this.$el.modal('hide')
    } else {
      this.$el.addClass('fade')
    }
  })

  handleOrientationChange = () => {
    // iPad does not trigger resize event after orientation change
    if (navigator.platform === 'iPad') {
      setTimeout(() => this.handleResize, 250)
    }
  }

  handleModalShow = () => {
    const { fullScreen } = this.props

    if (fullScreen) {
      document.body.classList.add('force-disable-scroll')
      requestFullscreen(this.$el[0])
    }
  }

  handleModalHidden = () => {
    const { fullScreen, onModalClosed } = this.props

    if (fullScreen) {
      document.body.classList.remove('force-disable-scroll')
      exitFullscreen()
    }

    if (onModalClosed) {
      onModalClosed()
    }
  }

  render () {
    const { children, id, mobileOnly, hideCloseIcon, centered, padded, name } = this.props
    const modalClass = classNames('modal modal-op',
      {
        'fade': !mobileOnly
      })

    const modalDialogClass = classNames('modal-dialog', {
      'modal-dialog--centered': centered,
      'modal-dialog--padded': padded
    })

    return (
      <div
        id={id}
        className={modalClass}
        tabIndex='-1' role='dialog'
        aria-hidden='true'
        ref={el => { this.$el = $(el) }}
      >
        <div className={modalDialogClass}>
          <div className='modal-content'>
            {!hideCloseIcon && (<button className='close' type='button' data-dismiss='modal' aria-label='close' data-automation-id={`${name}__modal-close-button`}>
              <span aria-hidden='true'>&times;</span>
            </button>)}

            <div id={id ? `${id}-body` : null} className='modal-body'>
              {children}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
