import React, { Component } from 'react'
import { ElementsConsumer, PaymentElement } from '@stripe/react-stripe-js'
import { FormattedMessage } from 'react-intl'
import Loader from '../components/Loader'
import classNames from 'classnames'

class StripeForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      gatewayError: null,
      invisible: true,
      buttonNameId: 'authentication.continue_booking'
    }
  }

  displayCardError (error) {
    if (error.type === 'validation_error') {
      // the form fields should have the error messages below them
      this.setState({ loading: false, stripeToken: null, gatewayError: null })
      this.props.threedsErrorSetter(false)
    } else if (error.code === 'setup_intent_authentication_failure') {
      this.setState({ loading: false, stripeToken: null, gatewayError: null })
      this.props.threedsErrorSetter(true)
    } else {
      this.setState({ loading: false, stripeToken: null, gatewayError: error.message })
      this.props.threedsErrorSetter(false)
    }
  }

  handleSubmit = async e => {
    e.preventDefault()

    const { stripe, elements } = this.props
    if (!elements) { return }

    try {
      this.setState({ loading: true })
      const urlParams = new URLSearchParams(window.location.search)
      const country = window._oneparkDatas.user_country
      const zipcode = window._oneparkDatas.user_zipcode
      const confirmation = await stripe.confirmSetup({
        elements,
        confirmParams: {
          payment_method_data: { billing_details: { address: { country, postal_code: zipcode } } },
          return_url: `${window.location.origin}/payment/setup-intents/callback` + '?' +
            new URLSearchParams({
              origin: window.location.pathname,
              offer_id: urlParams.get('offer_id'),
              action: urlParams.get('action'),
              flash_notice: 'card_added'
            }).toString()
        }
      })
      // for example, when the card does not have sufficient funds
      if (confirmation.error) {
        this.displayCardError(confirmation.error)
      } else {
        this.props.turnoffWarning()
      }

      // ELSE should not happen, it should always redirect:
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    } catch (error) {
      this.displayCardError(error)
    }
  }

  componentDidMount () {
    const innerTextSpan = document.getElementById('payment-details-submit-button-inner-text').childNodes[0]
    const outerButton = document.getElementById('payment-details-submit-button')
    if (outerButton.offsetWidth - innerTextSpan.offsetWidth < 40) {
      this.setState({ buttonNameId: 'authentication.continue_booking_short' })
    }
  }

  render () {
    const { loading, gatewayError, invisible } = this.state
    const { onCancel, callingPage, handleElementReady, handleStripeChange, localePrefix, threedsError, tabletPortraitExclusiveStyles } = this.props

    const buttonsClassName = classNames('payment-info-panel__buttons', {
      'payment-info-panel__buttons--tablet-portrait-exclusive': tabletPortraitExclusiveStyles
    })

    const legalNoticesClassName = classNames('payment-info-panel__legal-notice', {
      'payment-info-panel__legal-notice--tablet-portrait-exclusive': tabletPortraitExclusiveStyles
    })

    return (
      <>
        <form onSubmit={this.handleSubmit} className={invisible ? 'invisible' : ''}>
          <PaymentElement
            options={{ fields: { billingDetails: { address: { country: 'never', postalCode: 'never' } } }, terms: { card: 'never' } }}
            onReady={() => setTimeout(() => {
              handleElementReady()
              this.setState({ invisible: false })
            }, 1000)}
            onChange={(event) => { if (handleStripeChange) { handleStripeChange(event.complete) } }} />

          <div className={legalNoticesClassName}>
            <FormattedMessage id='stripe.legal_terms_before_link' />
            <a href={`${localePrefix}/privacy-policy`} >
              <FormattedMessage id='stripe.legal_terma_link' />
            </a>
          </div>

          <div className='payment-info-panel__error-message payment-info-panel__error-message--orange'
            data-automation-id='payment-info-panel__error-message'>
            {gatewayError &&
              <div>
                {gatewayError}
              </div>}
            {threedsError &&
              <div>
                <FormattedMessage id='authentication.registration.payment_info.3ds.error_message.before_link' />
                <a className='secure-3d-link' onClick={show3dsError}>
                  <FormattedMessage id='authentication.registration.payment_info.3ds.error_message.link_title' />
                </a>
              </div>}
          </div>

          <div className={buttonsClassName}>
            <button className='btn btn-primary' type='submit'
              disabled={loading} id='payment-details-submit-button' data-automation-id='payment-details__submit-button'>
              {loading && <Loader overlay white />}
              <span className={loading ? 'invisible' : ''}>
                <div className={`col-xs-12 col-sm-12 payment-details-submit-button`}
                  id='payment-details-submit-button-inner-text' data-automation-id='payment-details__submit-button__inner-text' >
                  <FormattedMessage id={this.state.buttonNameId} />
                </div>
              </span>
            </button>
            {onCancel && (
              <button className='btn btn-secondary-alt' type='button' onClick={onCancel}>
                <FormattedMessage id={callingPage === 'purchase' ? 'actions.cancel' : 'authentication.later'} />
              </button>
            )}
          </div>
        </form>
      </>
    )
  }
}

export default function InjectedStripeForm (props) {
  const { stripe, elements, ...otherProps } = props
  // The stripe and elements are promises, the special format below is for the Class components.
  // Without extracting otherProps the onCancel is not passed down to the StripeForm.
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <StripeForm stripe={stripe} elements={elements} {...otherProps} />
      )}
    </ElementsConsumer>
  )
}
