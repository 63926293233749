import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import InjectedStripeForm from './StripeForm'
import classNames from 'classnames'
import FormInput from '../components/Form/FormInput'
import {
  performValidation,
  genericCardholderValidation,
  genericCardNumberValidation,
  genericCardExpiryDateValidation,
  genericCryptogramValidation,
  getErrorFieldName
} from '../helpers/formValidations'
import { Info3dSecureDialog } from 'Components/Info3dSecureDialog'
import { OnPurchase3dSecureErrorDialog } from 'Components/OnPurchase3dSecureErrorDialog'
import { OnRegistration3dSecureErrorDialog } from 'Components/OnRegistration3dSecureErrorDialog'
import Loader from 'Components/Loader'

class PaymentInfoPanel extends Component {
  static propTypes = {
    expiresAt: PropTypes.string,
    ownerName: PropTypes.string,
    cardNumber: PropTypes.string,
    onCancel: PropTypes.func,
    green: PropTypes.bool,
    callingPage: PropTypes.oneOf(['registration', 'booking', 'purchase']).isRequired,
    threedsError: false
  }

  static defaultProps = {
    expiresAt: '',
    ownerName: '',
    cardNumber: ''
  }

  state = {
    acceptTerms: false,
    professionalUse: false,
    loading: false,
    showLoader: true,
    gatewayError: null,
    stripe: null,
    setupIntentSecret: null
  }

  fetchClientSecret = async () => {
    const setupIntentResponse = await fetch(`/payment/setup-intents`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })

    if (setupIntentResponse.ok) {
      const setupIntentJSON = await setupIntentResponse.json()
      return setupIntentJSON.data.attributes.client_secret
    } else {
      throw new Error(`Error while connecting to server (HTTP status ${setupIntentResponse.status})`)
    }
  }

  componentDidMount = async () => {
    const stripe = loadStripe(this.props.stripePublicKey, { apiVersion: '2022-11-15' })
    const clientSecret = await this.fetchClientSecret()
    this.setState({ stripe, setupIntentSecret: clientSecret })
  }

  bindHandleSet = (target, fieldName) => (value) => target.setState({ [fieldName]: value })

  bindHandleValidate = (target, name) => (value) => performValidation.call(target, validatorByName[name], value, getErrorFieldName(name))

  renderField = (target, { name, icon, labelId, body, inputType }) => {
    const errorFieldName = `${name}Error`
    return (
      <FormInput
        id={`${name}Field`}
        setCallback={target.props.bindHandleSet(target, name)}
        validateCallback={target.props.bindHandleValidate(target, name)}
        inputValue={target.state[name]}
        errorMessage={target.state[errorFieldName]}
        svgIcon={icon}
        placeholderKey={`authentication.registration.payment_info.${labelId}`}
      >
        {body || <input type={inputType || 'text'} className='form-control' />}
      </FormInput>
    )
  }

  renderTermsLink = (type, path) => {
    return (
      <a href={path} target='_blank' className='link--secondary'>
        <FormattedMessage id={`purchase.purchase_summary.${type}`} />
      </a>
    )
  }

  locationBeforeSummary = () => {
    // it is a bit ugly, maybe we should switch to the props instead of analyzing window.location
    if (typeof window === 'undefined') return true

    return window.location.pathname.endsWith('member/payment-informations')
  }

  editOnSummary = () => {
    if (typeof window === 'undefined') return false

    return window.location.pathname.endsWith('/purchases/summary')
  }

  show3dsInfo = (event) => { event.preventDefault(); $('#info-3d-secure').modal('show') }

  render () {
    const { stripe, setupIntentSecret } = this.state
    // https://stripe.com/docs/elements/appearance-api?platform=web
    const appearance = {
      theme: 'stripe',
      variables: {
        colorText: '#32325d',
        colorDanger: '#FF570C',
        fontFamily: 'Inter, Helvetica Neue, Helvetica, sans-serif',
        fontSmooth: 'always',
        fontSize: '14px',
        colorTextPlaceholder: '#757575',
        colorIconCardError: '#FF570C'
      }
    }
    const editingOnSummary = this.editOnSummary()
    const { currentLocale, handleStripeChange } = this.props

    const threeDsWarningClassName = classNames('threeds-warning__body threeds-warning__body--green text--grey-darker', {
      'threeds-warning__body--greyed': !this.locationBeforeSummary(),
      'threeds-warning__body--mobile-exclusive': this.locationBeforeSummary()
    })

    return (
      <div className='payment-info-panel'>
        <OnRegistration3dSecureErrorDialog />
        <OnPurchase3dSecureErrorDialog />
        <Info3dSecureDialog />
        {!this.props.hideTitle &&
          <h1>
            <FormattedMessage id='authentication.registration.payment_info.title' />
          </h1>
        }

        {this.locationBeforeSummary() &&
          <div className='payment-info-panel__payment-cards payment-info-panel__payment-cards_3ds'>
            <small className='text--grey-darker'>
              <FormattedMessage id='authentication.registration.payment_info.secure_payment' />
            </small>
          </div>
        }

        {!this.state.threedsError &&
          <div className={threeDsWarningClassName}>
            <FormattedMessage id='authentication.registration.payment_info.3ds.charge_warning.before_link' />
            <a href={'#'} onClick={this.show3dsInfo}>
              <FormattedMessage id='authentication.registration.payment_info.3ds.charge_warning.link_title' />
            </a>
            <FormattedMessage id='authentication.registration.payment_info.3ds.charge_warning.after_link' />
          </div>}

        <div className={`payment-info-panel__form ${this.state.showLoader ? 'payment-info-panel__form--loading' : ''}`}>
          {this.state.showLoader && <Loader overlay green />}
          {stripe && setupIntentSecret &&
            <Elements
              stripe={stripe}
              options={{ clientSecret: setupIntentSecret, locale: currentLocale, appearance }}>
              <InjectedStripeForm
                {...this.props}
                stripe={stripe}
                bindHandleSet={this.bindHandleSet}
                bindHandleValidate={this.bindHandleValidate}
                renderField={this.renderField}
                editingOnSummary={editingOnSummary}
                renderTermsLink={this.renderTermsLink}
                handleElementReady={() => this.setState({ showLoader: false })}
                turnoffWarning={this.props.turnoffWarning}
                threedsError={this.state.threedsError}
                threedsErrorSetter={(value) => {
                  this.setState({ threedsError: value })
                  if (this.props.handleStripeError) this.props.handleStripeError()
                }}
                handleStripeChange={handleStripeChange}
                tabletPortraitExclusiveStyles={this.locationBeforeSummary()}
              />
            </Elements>
          }
        </div>
      </div>
    )
  }
}

const validatorByName = {
  ownerName: genericCardholderValidation,
  cardNumber: genericCardNumberValidation,
  expiresAt: genericCardExpiryDateValidation,
  verificationCode: genericCryptogramValidation
}

const mapStateToProps = ({
  railsContext: {
    currentSite: { iso: country }, localePrefix, location, currentLocale
  },
  resources: { isDefaultHost }
}) => ({ country, localePrefix, location, isDefaultHost, currentLocale })

export default connect(mapStateToProps)(PaymentInfoPanel)
