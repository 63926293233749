import React, { Component } from 'react'
import classNames from 'classnames'
import { FormattedHTMLMessage } from 'react-intl'

export class Stepper extends Component {
  render () {
    const allSteps = [...this.props.previousSteps, this.props.activeStepId, ...this.props.futureSteps]
    const activeStep = this.props.previousSteps.length + 1
    const stepsCount = allSteps.length

    return (
      <div className={`stepper col-xs-12 col-sm-12 ${this.props.stepperClasses || ''}`}>
        {allSteps.map((messageId, index) => (
          <>
            <div key={index} className={
              classNames('stepper__step', {
                'stepper__step--first': (index === 0),
                'stepper__step--last': (index === stepsCount - 1),
                'stepper__step--grey': (activeStep <= index + 1),
                'stepper__step--active': (activeStep === index + 1),
                'stepper__step--blue': (activeStep > index + 1 || (activeStep === index + 1 && index === stepsCount - 1))
              })
            }>
              <div className='stepper__column' >
                <div
                  className={
                    classNames('stepper__circle', {
                      'stepper__circle--active': (activeStep === index + 1),
                      'stepper__circle--passed': (activeStep > index + 1)
                    })
                  }
                >
                  <p>{index + 1}</p>
                </div>
                <div className={
                  classNames('stepper__label', {
                    'stepper__label--active': (activeStep === index + 1),
                    'stepper__label--passed': (activeStep > index + 1)
                  })
                }>
                  <FormattedHTMLMessage id={`steps.${messageId}`} />
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    )
  }
}
