import React from 'react'
import URLSearchParams from 'url-search-params'

/*
 ** Bind all functions passed,
 ** useful in method-heavy components
 */
export function bindFunctions (functions) {
  functions.forEach(f => (this[f] = this[f].bind(this)))
}

/*
 * Displays an error in for of a Boostrap alert
 */
export function errorBox (value, additionalClasses = '') {
  return (
    <div className={'col-sm-12 ' + additionalClasses}>
      <div className='alert alert-form--error' role='alert'>
        {value}
      </div>
    </div>
  )
}

/*
 * Gets the parameters from the URL
 */
export function getParam () {
  const a = window.location.search.substr(1).split('&')
  if (a === '') return {}
  var b = {}
  for (var i = 0; i < a.length; ++i) {
    var p = a[i].split('=', 2)
    if (p.length === 1) { b[p[0]] = '' } else { b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' ')) }
  }
  return b
}

/*
 * Returns a cookie value, returns undefined if not found
 */
export function getCookieValue (name, defaultValue = null) {
  var b = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')
  return b ? b.pop() : defaultValue
}

/*
 * Set a cookie value
 */
export function setCookieValue (name, value, expiryInMinutes) {
  const d = new Date()
  d.setTime(d.getTime() + (expiryInMinutes * 60 * 1000))
  const expires = 'expires=' + d.toUTCString()
  document.cookie = name + '=' + value + ';' + expires + ';path=/'
}

export function removeCookie (name) {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

/*
 * Returns boolean => key/value is in url ?
 */
export function isParamInUrl (key, value) {
  const params = new URLSearchParams(window.location.search.slice(1))

  return params.get(key) === value
}

export const locationHasParam = (name, value) => (new URLSearchParams(location.search.slice(1))).get(name) === value

export const appendParamToLocation = (name, value) => {
  const params = new URLSearchParams(location.search.slice(1))
  params.append(name, value)
  return location.pathname + '?' + params.toString()
}

export const removeParamFromLocation = (...names) => {
  const params = new URLSearchParams(location.search.slice(1))
  names.forEach(name => params.delete(name))
  return location.pathname + (Array.from(params).length ? '?' : '') + params.toString()
}

/*
 * Ensure to get only full path of an url
*/
export function getURLFullPath (url) {
  const a = document.createElement('a')
  a.href = decodeURIComponent(url)
  return a.pathname + a.search + a.hash
}

/*
* Push in datalayers form
*/
export function defineAction () {
  const modalParams = new URLSearchParams(window.location.search.slice(1))
  const page = modalParams.get('modal') || window.location.pathname.split('/').pop()
  if (page === 'connect') {
    return 'Login'
  } else if (page === 'forgotten-password') {
    return 'Password-forgot'
  } else {
    return page.charAt(0).toUpperCase() + page.slice(1)
  }
}

/*
* Push in datalayers form
*/
export function datalayerPushGTM (event, gaCategory = null, gaAction = null, gaLabel = null, gaValue = null) {
  window.dataLayer && window.dataLayer.push({ event, gaCategory, gaAction, gaLabel, gaValue })
}

export function datalayerPush ({ event, ...props }) {
  window.dataLayer && window.dataLayer.push({ event, ...props })
}

export function datalayerPushTransaction (purchase) {
  window.dataLayer && window.dataLayer.push({
    event: 'transaction_op',
    transactionTotal: parseFloat(purchase.paidPrice),
    commission: parseFloat(purchase.commissionAmount),
    transactionWithoutCommission: parseFloat(purchase.partnerShare),
    transactionAffiliation: purchase.park.name,
    transactionId: purchase.booking.id,
    transactionProducts: [{
      name: purchase.park.name,
      sku: purchase.offer.title || 'Grille tarifaire',
      category: purchase.offer.type,
      price: parseFloat(purchase.paidPrice),
      quantity: 1
    }]
  })
}

export function datalayerPushNavigationEvent (clickedElement) {
  const gaParams = {
    event: 'navigation',
    ep_element_clicked: clickedElement,
    up_language: window._oneparkDatas.up_language,
    up_locale: window._oneparkDatas.up_locale
  }
  const upUserId = window._oneparkDatas.up_user_id

  if (upUserId) {
    gaParams['up_user_id'] = upUserId
  }

  window.dataLayer && window.dataLayer.push(gaParams)
}

export function searchDataLayerPush (params, address, type) {
  let endAt
  let durationInMinutes

  if (type === 'subscription') {
    endAt = params.beginDate.clone().add({ days: 30 })
    durationInMinutes = 30 * 24 * 60
  } else {
    endAt = params.endDate
    durationInMinutes = (endAt - params.beginDate) / (1000 * 60)
  }

  const pushParams = {
    event: 'search',
    ep_begin_at: params.beginDate.format(),
    ep_end_at: endAt.format(),
    ep_duration: durationInMinutes,
    ep_search_term: address,
    up_language: (window._oneparkDatas.up_language || null),
    up_locale: (window._oneparkDatas.up_locale || null)
  }

  if (window._oneparkDatas.up_user_id != null && window._oneparkDatas.up_user_id.length !== 0) pushParams.up_user_id = window._oneparkDatas.up_user_id

  datalayerPush(pushParams)
}

/*
* Is user agent a mobile
* FIXME Do NOT use me anymore. This is not consistant with Rails "browser.device.mobile?" for iPads
*/
export function isUserOnMobile () {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}
